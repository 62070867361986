import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./ContainerForm.css";
import L from "leaflet";

const customIcon = L.icon({
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png", // Replace with your custom icon URL
  // iconSize: [32, 32], // Adjust the size of the marker
  iconAnchor: [16, 32], // Adjust the anchor point
  popupAnchor: [0, -32], // Adjust popup position
});

interface ContainerFormProps {
  onSubmit: (data: {
    id: string;
    location: string;
    coordinates: { lat: number; lng: number };
    timeline: { date: string; location: string }[];
    ETD: string;
    ETA: string;
    portOfLoading: string;
    portOfDischarge: string;
    shipImage: string;
    shipName: string;
  }) => void;
  existingData?: {
    id: string;
    location: string;
    coordinates: { lat: number; lng: number };
    timeline: { date: string; location: string }[];
    ETD: string;
    ETA: string;
    portOfLoading: string;
    portOfDischarge: string;
    shipImage: string;
    shipName: string;
  };
}

const ContainerForm: React.FC<ContainerFormProps> = ({
  onSubmit,
  existingData,
}) => {
  const [id, setId] = useState(existingData?.id || "");
  const [location, setLocation] = useState(existingData?.location || "");
  const [lng, setLng] = useState(existingData?.coordinates.lng || 0);
  const [lat, setLat] = useState(existingData?.coordinates.lat || 0);
  const [timeline, setTimeline] = useState<
    { date: string; location: string }[]
  >(existingData?.timeline || []);
  const [ETD, setETD] = useState(existingData?.ETD || "");
  const [ETA, setETA] = useState(existingData?.ETA || "");
  const [portOfLoading, setPortOfLoading] = useState(
    existingData?.portOfLoading || ""
  );
  const [portOfDischarge, setPortOfDischarge] = useState(
    existingData?.portOfDischarge || ""
  );
  const [shipImage, setShipImage] = useState(existingData?.shipImage || "");
  const [shipName, setShipName] = useState(existingData?.shipName || "");

  const handleAddTimeline = () => {
    setTimeline([...timeline, { date: "", location: "" }]);
  };

  const handleTimelineChange = (
    index: number,
    field: "date" | "location",
    value: string
  ) => {
    const updatedTimeline = [...timeline];
    updatedTimeline[index][field] = value;
    setTimeline(updatedTimeline);
  };

  const handleSubmit = () => {
    if (
      !id.trim() ||
      !location.trim() ||
      !ETD.trim() ||
      !ETA.trim() ||
      !portOfLoading.trim() ||
      !portOfDischarge.trim() ||
      !shipName.trim() ||
      !shipImage.trim()
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    onSubmit({
      id,
      location,
      coordinates: { lat, lng },
      timeline,
      ETD,
      ETA,
      portOfLoading,
      portOfDischarge,
      shipImage,
      shipName,
    });

    setId("");
    setLocation("");
    setLng(0);
    setLat(0);
    setTimeline([]);
    setETD("");
    setETA("");
    setPortOfLoading("");
    setPortOfDischarge("");
    setShipImage("");
    setShipName("");
  };

  const MapClickHandler: React.FC = () => {
    useMapEvents({
      click: (e) => {
        setLat(e.latlng.lat);
        setLng(e.latlng.lng);
      },
    });
    return null;
  };

  return (
    <div className="container-form">
      <h3>{existingData ? "Edit Container" : "Add New Container"}</h3>
      <div className="form-group">
        <label>Container ID</label>
        <input type="text" value={id} onChange={(e) => setId(e.target.value)} />
      </div>
      <div className="form-group">
        <label>Current Location</label>
        <input
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Longitude (Lng)</label>
        <input
          type="number"
          value={lng}
          onChange={(e) => setLng(parseFloat(e.target.value))}
        />
      </div>
      <div className="form-group">
        <label>Latitude (Lat)</label>
        <input
          type="number"
          value={lat}
          onChange={(e) => setLat(parseFloat(e.target.value))}
        />
      </div>
      <div className="form-group map-container">
        <label>Select Coordinates from Map</label>
        <MapContainer
          center={[lat || 51.505, lng || -0.09]}
          zoom={13}
          style={{ width: "100%", height: "300px" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; OpenStreetMap contributors"
          />
          <MapClickHandler />
          <Marker position={[lat, lng]} icon={customIcon} />
        </MapContainer>
      </div>
      <div className="form-group">
        <label>ETD</label>
        <input
          type="datetime-local"
          value={ETD}
          onChange={(e) => setETD(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>ETA</label>
        <input
          type="datetime-local"
          value={ETA}
          onChange={(e) => setETA(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Port of Loading</label>
        <input
          type="text"
          value={portOfLoading}
          onChange={(e) => setPortOfLoading(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Port of Discharge</label>
        <input
          type="text"
          value={portOfDischarge}
          onChange={(e) => setPortOfDischarge(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Ship Name</label>
        <input
          type="text"
          value={shipName}
          onChange={(e) => setShipName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Ship Image URL</label>
        <input
          type="text"
          value={shipImage}
          onChange={(e) => setShipImage(e.target.value)}
        />
      </div>
      <button className="btn btn-primary" onClick={handleSubmit}>
        {existingData ? "Update Container" : "Add Container"}
      </button>
    </div>
  );
};

export default ContainerForm;
